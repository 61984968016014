import '../App.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";

import { Config } from '../config';
import VTPButton from '../components/button';

const Login = () => {
  const msalContext = useMsal();

  const handleLogin = () => {
    msalContext.instance.loginRedirect({
      scopes: Config.oidcScopes,
      redirectStartPage: Config.msalConfig.auth.redirectUri,
    });
  };

  return (
    <Row xs={12} md={8}>
      <Col>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <h1 className={'header'}>Bridge Dashboard</h1>
          </Col>
        </Row>
        <Row className={'center'}>
          <Col
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Row className={'center'}>
              <Col className={'center-horz'} xs={8} md={3}>
                <p className={'std-text'}>
                  Login with your Microsoft Active Directory account.
                </p>
              </Col>
            </Row>
            <Row className={'center'}>
              <Col
                className={'center'}
                style={{
                  marginTop: '30px',
                }}
                md={2}
                xs={6}
              >
                <VTPButton onClick={handleLogin}>Log in</VTPButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
