const env = window as any;
export class Config {
    // Dashboard routes
    static loginRoute = "/login";
    static dashboardRoute = "/dashboard";
    static loginDeviceBrowserRoute = "/logindevice";
    static returnDeviceBrowserRoute = "/returndevice";
    static loginDeviceCodeRoute = "/code";

    // API config
    static clientName = env.CLIENT_NAME;
    static environment = env.ENVIRONMENT;
    static versionTag = env.VERSION_TAG;
    static envUrlPrefix = env.ENV_URL_PREFIX;
    static apiHostname = env.API_HOST_NAME;
    static clientUrlSuffix = env.CLIENT_URL_SUFFIX;
    static baseV2Host = `${Config.envUrlPrefix}${Config.apiHostname}${Config.clientUrlSuffix}/v2`
    static dashboardOrigin = window.location.protocol + "//" + window.location.hostname;

    // OpenID Connect config
    static oidcScopes = ["openid", "profile"];
    static oidcDeviceCodeInputUrl = env.OIDC_DEVICE_CODE_INPUT_URL;
    static msalConfig = {
        auth: {
            clientId: env.OIDC_CLIENT_ID,
            authority: env.OIDC_AUTHORITY,
            redirectUri: '/dashboard',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: 'sessionStorage'
        }
    };
}