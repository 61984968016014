import { AccountInfo, AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { Config } from "../config";
import { IBridgeCredentials } from "./apiModels";

class AuthUtilities {
    public static CheckUserAuthStatus(msalInstance: IPublicClientApplication): Promise<boolean> {
        return msalInstance.acquireTokenSilent({
            scopes: Config.oidcScopes,
            account: msalInstance.getAllAccounts()[0]
        }).then((authRes: AuthenticationResult) => {
            return true;
        }).catch((error: any) => {
            return false;
        });
    }

    public static TryGetAccountFromRedirectResult(
        redirectResult: AuthenticationResult | null,
        msalInstance: IPublicClientApplication): AccountInfo | null {
        if (redirectResult)
            return redirectResult.account;

        const allAccounts = msalInstance.getAllAccounts();
        if (allAccounts.length > 0)
            return allAccounts[0];

        return null;
    }

    public static GetNameFromAuthResult(auth: AuthenticationResult): string {
        if (auth.account == null)
            return "Unknown";

        return this.GetNameFromAuthAccount(auth.account);
    }

    public static GetNameFromAuthAccounts(accounts: AccountInfo[]): string {
        if (accounts.length < 1)
            return "Unknown";

        return this.GetNameFromAuthAccount(accounts[0]);
    }

    public static GetNameFromAuthAccount(account: AccountInfo, getFullName = false): string {
        if (account == null)
            return "Unknown";

        let name = account.name;
        if (name == null)
            name = account.username;

        if (getFullName)
            return name;
        else
            return this.GetFirstName(name);
    }

    public static GetFirstName(name: string): string {
        if (name.includes(" "))
            name = name.split(" ")[0]

        return name;
    }

    public static StoreBridgeCredentials(credentials: IBridgeCredentials): void {
        sessionStorage.setItem('bridgeCredentials', JSON.stringify(credentials));
    }

    public static ClearBridgeCredentials(): void {
        sessionStorage.removeItem('bridgeCredentials');
    }

    public static StartDeviceCodeInput() {
        // Try to open code window in popup, otherwise redirect
        if (!window.open(Config.oidcDeviceCodeInputUrl))
            this.Redirect(Config.oidcDeviceCodeInputUrl);
    }

    public static Redirect(route: string) {
        window.location.href = route;    
    }
}

export default AuthUtilities;