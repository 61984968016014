import '../App.css';

import React, { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useMsal } from "@azure/msal-react";
import { useEffect } from 'react';
import AuthUtilities from '../lib/authUtilities';
import { AuthenticationResult } from '@azure/msal-browser';
import { Config } from '../config';

const ReturnDeviceBrowser = () => {
  const [authorized, setAuthorized] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const msalContext = useMsal();

  useEffect(() => {
    msalContext.instance.handleRedirectPromise()
      .then((redirectResult: AuthenticationResult | null) => {
        const account = AuthUtilities.TryGetAccountFromRedirectResult(redirectResult, msalContext.instance)
        if (account != null) {
          msalContext.instance.acquireTokenSilent({
            scopes: Config.oidcScopes,
            account: account
          }).then((authRes: AuthenticationResult) => {
            setAuthorized(true);
            setInitialized(true);

            AuthUtilities.StoreBridgeCredentials({
              "name": AuthUtilities.GetNameFromAuthAccount(account, true),
              "idToken": authRes.idToken,
              "expiresOn": authRes.expiresOn
            });
          }).catch((error: any) => {
            setAuthorized(false);
            setInitialized(true);
          });
        }
        else {
          setAuthorized(false);
          setInitialized(true);
        }
      });
  });

  return (
    <Row xs={12} md={8}>
      <Col>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <h1 className={'header'}>{!initialized
              ? "Fetching your credentials"
              : !authorized
                ? "Login failed"
                : "Synchronizing credentials"}</h1>
          </Col>
        </Row>
        <Row className={'center'}>
          <Col
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Row className={'center'}>
              <Col
                className={'center'}
                style={{
                  marginTop: '30px',
                }}
                md={1}
                xs={1}
              >
                {initialized && !authorized ? <div /> : <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ReturnDeviceBrowser;
