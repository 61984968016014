import './App.css';

import { Col, Container, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { Config } from './config'

import Dashboard from './pages/dashboard';
import Header from './components/header';
import Login from './pages/login';

import NetworkManager from './lib/networkManager';
import AuthUtilities from './lib/authUtilities';

import LoginDeviceBrowser from './pages/loginDeviceBrowser';
import ReturnDeviceBrowser from './pages/returnDeviceBrowser';
import LoginDeviceCode from './pages/loginDeviceCode';

const BackgroundGradient = () => {
  return (
    <div>
      <div
        className={'shape-1 '}
        style={{ height: '82vh', width: '100%', marginTop: '70px' }}
      ></div>
      <div
        className={'shape-2 '}
        style={{ height: '79vh', width: '100%', marginTop: '70px' }}
      ></div>
      <div
        className={'shape'}
        style={{ height: '75vh', width: '100%', marginTop: '70px' }}
      ></div>
    </div>

  );
};

class App extends Component {
  networkManager: NetworkManager = NetworkManager.getInstance();
  authManager: PublicClientApplication = new PublicClientApplication(Config.msalConfig);

  state = {
    authorized: false,
    versionWasLogged: false
  };

  componentDidMount() {
    AuthUtilities.CheckUserAuthStatus(this.authManager).then(isAuthorized => {
      this.setState({ authorized: isAuthorized });
    })
  }

  logAndRenderVersionTag() {
    if (!this.state.versionWasLogged)
    {
      console.log(`[Bridge Dashboard]\nClient name: ${Config.clientName}\nEnvironment: ${Config.environment}\nVersion: ${Config.versionTag}`);
      this.setState({ versionWasLogged: true });
    }

    // Only render version tag on non-production dashboards
    if (Config.environment != "prod")
      return  <Row className="fixed-bottom" style={{ justifyContent: 'center', backgroundColor: '#292929'}}>
                <h6 style={{fontFamily: 'Nats-Regular', color: '#ffffff' }}>
                  {Config.clientName} | {Config.environment} | {Config.versionTag}</h6>
              </Row>
    else
      return null;
  }

  render() {
    return (
      <MsalProvider instance={this.authManager}>
        <Router>
          <div style={{ justifyContent: 'center' }}>
            <BackgroundGradient />
            <Container>
              <Row>
                <Header />
              </Row>
              <Row
                xs={12}
                md={8}
                style={{
                  paddingTop: '170px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Col xs={12} md={12} style={{ justifyContent: 'center' }}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return this.state.authorized ? (
                          <Redirect push to="/dashboard" />
                        ) : (
                          <Login />
                        );
                      }}
                    />
                    <Route path={Config.loginRoute} component={Login} />
                    <Route path={Config.dashboardRoute} component={Dashboard} />
                    <Route path={Config.loginDeviceBrowserRoute} component={LoginDeviceBrowser} />
                    <Route path={Config.returnDeviceBrowserRoute} component={ReturnDeviceBrowser} />
                    <Route path={Config.loginDeviceCodeRoute} component={LoginDeviceCode} />
                  </Switch>
                </Col>
                {this.logAndRenderVersionTag()}
              </Row>
            </Container>
          </div>
        </Router>
      </MsalProvider>
    );
  }
}

export default App;