import { Col, Container, Row } from 'react-bootstrap';
import ErrorAlert, {
  AlertInterface,
} from '../components/responsehandling/errorAlert';
import React, { Component } from 'react';
import { MsalContext } from "@azure/msal-react";
import { AuthenticationResult, AccountInfo, Logger } from "@azure/msal-common";

import CardWidget from '../components/cardWidget';
import { Config } from '../config';
import NetworkManager from '../lib/networkManager';
import { RouteComponentProps } from 'react-router-dom';
import constructionImg from '../assets/img/dashboard/construction_iso.png';
import AuthUtilities from '../lib/authUtilities';
import { IErrorResponse, ICheckInResponse, IQuickCodeCreateResponse, IQuickCodeConsumeResponse } from "../lib/apiModels"

type TParams = { id: string | undefined } & RouteComponentProps;

class Dashboard extends Component<TParams> {
  static contextType = MsalContext;
  networkManager: NetworkManager = NetworkManager.getInstance();
  timer: NodeJS.Timeout | undefined;
  checkCodeValidity: NodeJS.Timeout | undefined;

  state = {
    username: '',
    alert: { show: false, message: '', status: '' } as AlertInterface,
  };

  componentDidMount() {
    this.handleLogin();
  }

  render() {
    return (
      <Container>
        <Row
          md={8}
          xs={12}
          noGutters={true}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
            marginBottom: '15vh',
          }}
        >
          <Col
            md={6}
            xs={12}
            style={{
              marginBottom: '30px',
              marginTop: '0px',
            }}
          >
            <h1
              style={{
                color: '#ffffff',
                fontFamily: 'Nats-Regular',
                fontSize: '60px',
              }}
            >
              Welcome, {this.state.username}
            </h1>
            <h6 style={{ color: '#ffffff' }}>
              The Bridge Dashboard is currently under construction.
            </h6>
            <Row style={{ justifyContent: 'center' }}>
              <img src={constructionImg} alt="Construction" />
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <Row
              style={{
                justifyContent: 'center',
              }}
            >
              <CardWidget
                onClick={() => {
                  AuthUtilities.StartDeviceCodeInput();
                }}
                cardTitle="Quick code"
                cardDescription="Open Bridge on your device and generate a quick code. Input the code using the link below"
                buttonText="Input code"
              />
            </Row>
          </Col>
        </Row>
        <ErrorAlert
          show={this.state.alert.show}
          onUpdateShow={() => this.resetAlert()}
          header={this.state.alert.message}
          message={this.state.alert.status}
        />
      </Container>
    );
  }

  handleLogin() {
    // Try to catch any auth info coming from redirect
    this.context.instance.handleRedirectPromise()
      .then((redirectResult: AuthenticationResult | null) => {
        // Determine account to use
        const account = AuthUtilities.TryGetAccountFromRedirectResult(redirectResult, this.context.instance)
        if (account != null) {
          // Grab user name from ID token
          this.setState({ username: AuthUtilities.GetNameFromAuthAccount(account) });

          // Try to check in the dashboard and keep checking in every 5 minutes
          this.tryCheckIn(account);
          setInterval(() => this.tryCheckIn(account), 300000);
        }
        else
        {
          console.error("Couldn't find account for logging in user.");
          AuthUtilities.Redirect(Config.loginRoute);
        }
      });
  }

  tryCheckIn = async (account: AccountInfo) => {
    // Try to get ID token silently
    this.context.instance.acquireTokenSilent({
      scopes: Config.oidcScopes,
      account: account
    }).then((authRes: AuthenticationResult) => {
      this.setState({ username: AuthUtilities.GetNameFromAuthResult(authRes) });

      // Try checking in and make alert if API not available
      this.networkManager.CheckIn(authRes.idToken).then(checkInRes => {
        if (checkInRes.status != 200) {
          const errorInfo = checkInRes.data as IErrorResponse;
          this.setState({
            alert: {
              show: true,
              message:
                'Sorry, we cannot reach the backend service. Try again later or contact a Bridge admin.',
              status: 'Error code: ' + (errorInfo?.bridgeErrorCode ?? "bridge-001-servererror"),
            },
          });
        }
      });
    }).catch((error: any) => {
      console.error("There was a problem logging in user: " + JSON.stringify(error));
      window.location.href = "/login"
    });
  };

  resetAlert = () => {
    this.setState({
      alert: {
        show: false,
        message: '',
        status: '',
      },
    });
  };
}

export default Dashboard;
