import { Container, Navbar } from 'react-bootstrap';
import { Link, Route } from 'react-router-dom';
import React, { Component } from 'react';

import Col from 'react-bootstrap/Col';
import { Config } from '../config';
import NetworkManager from '../lib/networkManager';
import ProfileArea from './Navbar/profileArea-default';
import ProfileAreaMobile from './Navbar/profileArea-mobile';
import Row from 'react-bootstrap/Row';
import { isMobile } from 'react-device-detect';
import logo from '../assets/bridge_logo.svg';
import { MsalContext } from '@azure/msal-react';
import AuthUtilities from '../lib/authUtilities';
import { AuthenticationResult } from '@azure/msal-browser';

class Header extends Component {
  static contextType = MsalContext;
  networkManager: NetworkManager = NetworkManager.getInstance();

  headerTitle = '20px';

  state = {
    username: '',
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.getUserInfo();
  }

  handleLogout = async () => {
    AuthUtilities.ClearBridgeCredentials();
    this.context.instance.logoutRedirect();
  };

  getUserInfo = async () => {
    try {
      this.context.instance.handleRedirectPromise().then((redirectResult: AuthenticationResult | null) => {
        let account;
        if (redirectResult) {
          account = redirectResult.account;
        } else {
          account = this.context.instance.getAllAccounts()[0];
        }

        this.setState({ username: AuthUtilities.GetNameFromAuthAccount(account) });
      });
    } catch (error: any) {
      console.error("There was a problem logging in user: " + JSON.stringify(error));
    }

    const accounts = this.context.instance.getAllAccounts();
    this.setState({ username: AuthUtilities.GetNameFromAuthAccounts(accounts) });
  };

  render() {
    return (
      <Navbar
        bg="dark"
        variant="dark"
        fixed="top"
        style={{ backgroundColor: '#292929' }}
        className="d-inline-block align-top"
      >
        <Container>
          <Navbar.Brand href="#" style={{ flex: 1 }}>
            <Row style={{ alignItems: 'center', flex: 1 }} noGutters>
              <Col xs={4}>
                <Link to="/dashboard" style={{ flex: 1 }}>
                  <Row style={{ alignItems: 'center' }} noGutters={true}>
                    <img
                      src={logo}
                      alt={''}
                      width="60"
                      height="60"
                      style={{ margin: '0px 15px' }}
                    />
                    {isMobile ? undefined : (
                      <h1
                        style={{
                          fontSize: this.headerTitle,
                          fontFamily: 'Nats-Regular',
                          color: '#ffffff',
                          paddingTop: '5px',
                        }}
                      >
                        Bridge Dashboard
                      </h1>
                    )}
                  </Row>
                </Link>
              </Col>
              <Col xs={8}>
                <Route exact path="/dashboard">
                  {isMobile ? (
                    <ProfileAreaMobile
                      handlelogout={this.handleLogout}
                      username={this.state.username}
                    ></ProfileAreaMobile>
                  ) : (
                    <ProfileArea
                      handlelogout={() => {
                        this.handleLogout();
                      }}
                      username={this.state.username}
                    ></ProfileArea>
                  )}
                </Route>
              </Col>
            </Row>
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
