import { Card, Row, Spinner } from 'react-bootstrap';
import React, { MouseEventHandler, PropsWithChildren } from 'react';

import VTPButton from './button';

interface Props extends PropsWithChildren<any> {
  onClick: MouseEventHandler;
  cardTitle: string
  cardDescription: string
  buttonText: string
}

const CardWidget: React.FC<Props> = (props: Props) => {
  return (
    <Card
      style={{
        padding: '40px 50px',
        minHeight: '360px',
        maxHeight: '360px',
        maxWidth: '310px',
        aspectRatio: '1.16',
        justifyContent: 'start',
        alignItems: 'center',
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
      }}
    >
      <Row style={{ justifyContent: 'start', flex: '1' }} noGutters={true}>
        <Card.Title
          style={{
            textAlign: 'left',
            fontSize: '42px',
            fontFamily: 'Nats-Regular',
            marginBottom: '5px',
            paddingBottom: '0px',
          }}
        >
          {props.cardTitle}
        </Card.Title>
        <Card.Text
          style={{
            textAlign: 'left',
            fontSize: '20px',
            color: '#696969',
            fontFamily: 'Nats-Regular',
            paddingTop: '0px',
          }}
        >
          {props.cardDescription}
        </Card.Text>
      </Row>
      <VTPButton
        color={'#259528'}
        onClick={props.onClick}
        style={{ width: '100%', height: '40pt' }}
      >
        {props.buttonText}
      </VTPButton>
    </Card>
  );
};

export default CardWidget;
